<template>
  <div
    :class="
      isUnderlineVisible
        ? 'border-solid border-b border-neutral-background-disabled'
        : null
    "
    class="bitts-collapse"
    @click="(e) => e.stopPropagation()"
  >
    <collapse
      v-model:activeKey="activeKey"
      :bordered="false"
      :expand-icon="expandIcon"
      :expand-icon-position="expandIconPosition"
      :collapsible="isDisabled ? 'disabled' : undefined"
      @change="onChange"
    >
      <template #expandIcon="props">
        <div
          v-if="expandIconPosition === 'end' && !noCollapse"
          :class="iconClasses()"
        >
          <FontAwesomeIcon
            :icon="['fak', !props.isActive ? 'chevron-right' : 'chevron-down']"
            :style="{ height: '16px', width: '16px', color: 'currentColor' }"
            class="text-neutral-text-placeholder"
          />
        </div>
        <div v-else-if="!noCollapse" class="flex justify-center items-center">
          <FontAwesomeIcon
            :icon="['fak', !props.isActive ? 'chevron-right' : 'chevron-down']"
            :style="{ height: '16px', width: '16px', color: 'currentColor' }"
            class="text-neutral-text-placeholder"
          />
        </div>
      </template>
      <collapse-panel v-bind="panelProps" key="1" :class="getPanelClasses">
        <template #header>
          <slot name="header" :is-open="activeKey.includes('1')" />
        </template>
        <template #extra>
          <slot name="extra" />
          <div
            v-if="isOpen"
            class="bitts-collapse__extra"
            @click="(e) => e.stopPropagation()"
          >
            <slot name="extra-content" />
          </div>
        </template>

        <div class="bitts-collapse__content">
          <slot />
        </div>
      </collapse-panel>
    </collapse>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { Collapse } from 'ant-design-vue';

export default {
  name: 'BittsCollapse',

  components: {
    Collapse,
    CollapsePanel: Collapse.Panel,
    FontAwesomeIcon,
  },

  props: {
    header: {
      type: String,
      default: '',
    },
    isDefaultOpen: {
      type: Boolean,
      default: false,
    },
    expandIconPosition: {
      type: String,
      default: 'start',
    },
    noCollapse: {
      type: Boolean,
      default: false,
    },
    showUnderline: {
      type: Boolean,
      default: true,
    },
    expandIcon: {
      type: Function,
      default: undefined,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['change'],
  data() {
    return {
      activeKey: '',
      isOpen: false,
    };
  },
  computed: {
    getPanelClasses() {
      let classes = 'text-neutral-text-strong text-lg';
      if (this.noCollapse) {
        classes += 'font-normal';
      }
      if (!this.header) {
        classes += ' bitts-collapse__collapse-no-header';
      }
      return classes;
    },
    isUnderlineVisible() {
      if (!this.showUnderline) {
        return false;
      }
      return !this.isOpen;
    },
    panelProps() {
      const props = {};
      if (this.header) {
        props.header = this.header;
      }
      return props;
    },
  },
  watch: {
    isDefaultOpen() {
      if (!this.noCollapse) {
        if (this.isDefaultOpen) {
          this.activeKey = ['1'];
        } else {
          this.activeKey = '';
        }
      }
    },
  },
  created() {
    if (this.noCollapse || this.isDefaultOpen) {
      this.activeKey = ['1'];
    }
  },
  methods: {
    iconClasses() {
      const classes = ['flex justify-center items-center'];
      if (this.noCollapse) {
        classes.push('hidden cursor-default');
      }
      return classes;
    },
    onChange() {
      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);
    },
  },
};
</script>

<style lang="pcss">
.bitts-collapse {
  .ant-collapse {
    font-family: inherit;
  }
  .ant-collapse-borderless {
    background-color: transparent;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-expand-icon {
    @apply pe-0;
  }

  .ant-collapse-header {
    @apply font-bold !pb-0;
  }

  .ant-collapse-item-active > .ant-collapse-header {
    @apply !pb-8;
  }

  .ant-collapse-borderless > .ant-collapse-item {
    border: none;
  }

  .ant-collapse .ant-collapse-item-disabled > .ant-collapse-header {
    @apply text-lg text-neutral-text-strong cursor-default;
  }

  .ant-collapse-item > .ant-collapse-header {
    @apply flex items-center justify-start px-0 pt-0;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header {
    @apply p-0 mb-16;
  }

  .ant-collapse-icon-position-left > .ant-collapse-item > .ant-collapse-header {
    @apply mb-16 py-0;
  }

  .bitts-collapse__no-header {
    .ant-collapse-header {
      @apply m-0;
    }
  }

  .bitts-collapse__no-header > .ant-collapse-header {
    margin: 0 !important;
  }

  .ant-collapse-borderless
    > .ant-collapse-item
    > .ant-collapse-content
    > .ant-collapse-content-box {
    @apply p-0;
  }

  .ant-collapse-icon-position-right
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 0;
  }

  .ant-collapse-icon-position-left
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    left: 0;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    @apply mr-8;
  }
}
</style>
